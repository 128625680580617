export const fakeData = {
  topRows: [
    { id: 1, name: 'APPL', votes: 190, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 2, name: 'BTC', votes: 132, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 3, name: 'ETH', votes: 412, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 4, name: 'BTC', votes: 642, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 5, name: 'APPL', votes: 222, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 6, name: 'BTC', votes: 123, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 7, name: 'BTC', votes: 312, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 8, name: 'APPL', votes: 512, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 9, name: 'APPL', votes: 123, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 10, name: 'ETH', votes: 412, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
    { id: 11, name: 'ETH', votes: 512, info: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim...' },
  ],
  recommendationColumns: [
    { id: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'buy', headerName: 'Buy', width: 130 },
    { field: 'time', headerName: 'Time', width: 130 },
    { field: 'comment', headerName: 'Comment', width: 400 }
  ],

  recommendationRows: [
    { id: 1, name: 'Alex', buy: '$239', time: '4:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 2, name: 'David', buy: '$139', time: '3:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 3, name: 'Alex', buy: '$249', time: '1:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 4, name: 'David', buy: '$539', time: '2:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 5, name: 'Alex', buy: '$429', time: '6:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 6, name: 'Sofia', buy: '$139', time: '1:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
    { id: 7, name: 'Alex', buy: '$369', time: '3:50 pm', comment: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim' },
  ]
}