import colors from "../_config/colors";
import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.main,
    },
    secondary: {
      main: colors.secondary,
    },
    common: {
      white: colors.white,
      black: colors.main,
    },
    background: {
      default: colors.lightgrey,
    },
    text: {
      primary: colors.main,
      secondary: colors.darkgrey,
    },
    error: { main: colors.red },
  },
  typography: {
    fontFamily: '"Jost", sans-serif',
    fontWeightBold: 500,
    h1: {
      fontWeight: 500,
      fontSize: 26,
      lineHeight: 1.31,
      "@media (min-width: 960px)": {
        fontSize: 44,
        lineHeight: 1.14,
      },
    },
    h2: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.44,
      "@media (min-width: 960px)": {
        fontSize: 26,
        lineHeight: 1.31,
      },
    },
    caption: {
      fontSize: 14,
    },
  },
  props: {
    MuiButton: {
      variant: "outlined",
    }    
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        lineHeight: 1.5,
        borderRadius: 4,
        textTransform: "capitalize",
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        paddingVertical: 10,
      },
      notchedOutline: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: colors.main,
        "&:hover": {
          borderColor: colors.main,
        },
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '10px 30px 10px',
        width: '100%'
      }
    },
    MuiCard: {
      root: {
        padding: 15
      }
    }
  }
});

export default theme;
