import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { CreateW } from './style';

export const Bsc = ({handleTabValue}) => {

  const user = useSelector(state => state.userReducer.user);

  return (
    <CreateW.Column>
      <CreateW.Title>
        {`Congratulations ${user.reddit_name}. You are entitled to 100 free $TOPS tokens`}
      </CreateW.Title>
      <CreateW.Subtitle>
        In order to use Binance Smart Chain (BSC) we need to add it to the Metamask. <br/>
        Please follow this guide:
      </CreateW.Subtitle>
      <CreateW.TextContainer>
        <CreateW.TextBody>
          <CreateW.InfoText>
            1. Switch the connected blockchain by clicking on the tab  saying
            Main Ethereum Network, we need to add the Binance Smart Chain network.
          </CreateW.InfoText>
          <CreateW.TutorImg src="/images/men.png" alt="men_img"/>
          <CreateW.InfoText>
            2. Scroll down until you find Custom RPC.
          </CreateW.InfoText>
          <CreateW.TutorImg src="/images/rpc.png" alt="rpc_img"/>
          <CreateW.InfoText>
            3. Enter in the BSC mainnet details as follows:
            <ul>
              <li>Network Name: BSC Mainnet</li>
              <li>
                New RPC URL: &nbsp; 
                <a href='https://bsc-dataseed1.binance.org/' target='_blank' rel="noreferrer">
                  https://bsc-dataseed1.binance.org/
                </a>
                &nbsp; <br/> or &nbsp;
                <a href='https://bsc-dataseed2.binance.org/' target='_blank' rel="noreferrer">
                  https://bsc-dataseed2.binance.org/
                </a>
              </li>
              <li>ChainID: 56, or 0x38 if 56 doesn't work</li>
              <li>Symbol: BNB</li>
              <li>
                "Block Explorer": 
                <a href='https://bscscan.com' target='_blank' rel="noreferrer"> https://bscscan.com</a>
              </li>
            </ul>          
          </CreateW.InfoText>
          <CreateW.TutorImg src="/images/bsc_mainnet.png" alt="bsc_mainnet_img"/>
          <CreateW.InfoText>
            4. Click Save. You have now connected to the BSC mainnet with your Metamask Wallet.
          </CreateW.InfoText>
        </CreateW.TextBody>
      </CreateW.TextContainer>
      <CreateW.ButtonsContainer>
        <Button 
            variant='contained'
            color='primary'
            onClick={() => handleTabValue('bsc', 1)}
          >
            Back
          </Button>
        <Button
          style={{ backgroundColor: 'green', marginLeft: 20 }}
          variant='contained'
          color='primary'
          onClick={() => handleTabValue('bsc', 5)}
        >
          Proceed
        </Button>
      </CreateW.ButtonsContainer>
    </CreateW.Column>
  )
}