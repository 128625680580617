import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../_actions';
import { ModalS } from './style';
import moment from 'moment';

export const CustomModal = () => {

  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modalReducer);
  const { isOpen, data } = modalState;

  const handleCloseModal = () => {
    modalActions.hideModal(dispatch);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
    >
      <ModalS.Body>
        <ModalS.InfoContainer>
          <ModalS.InfoTitle>
            Pay attention, it is possible to vote once every 24 hours :(
          </ModalS.InfoTitle>
          <ModalS.Description>
            <div style={{ marginLeft: 20 }}>Last vote created by you</div>
            <ul>
              <li>Symbol: { data.symbol && data.symbol.displaySymbol }</li>
              <li>Comment: { data.comment }</li>
              <li>Created at: { moment(data.created_at).format("MMM Do YY") }</li>
            </ul>
          </ModalS.Description>
        </ModalS.InfoContainer>
        <Button
          style={ModalS.CloseButton}
          variant='contained'
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </ModalS.Body>
    </Dialog>
  )
}