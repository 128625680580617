import { SHOW_ALERT, HIDE_ALERT } from "../_constants/action.types";

export const alertActions = {
  showAlert,
  hideAlert
}

function showAlert(data, dispatch) {
  return dispatch({ type: SHOW_ALERT, data })
}

function hideAlert(dispatch) {
  return dispatch({ type: HIDE_ALERT })
}