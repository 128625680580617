import React from 'react';
import { NotMember } from './style';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { userActions } from '../../_actions/index';

export const NotAMember = () => {

  const history = useHistory();  
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);

  const goToHome = () => {
    // Yeah, flow through this function is crappy. 
    // to many actions for small verification field.
    userActions.setVerifiedMembership(dispatch);
    history.push('/');
  }

  return(
    <NotMember.Container>
        <NotMember.TitleContainer>
          Sorry ${user.reddit_name}.  you are not entitled to free 
            tokens because you were not a member of the needed  
            <a href='https://www.reddit.com/r/TopStonks/' target='_blank'> subreddits</a> before Jan 1, 2021
        </NotMember.TitleContainer>
        <Button 
          variant='contained'
          onClick={goToHome}
        >
          Go to home page
        </Button>
    </NotMember.Container>
  )
}