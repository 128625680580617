import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HomeS } from './style';
import {
  CardContent,
  Card,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import colors from '../../_config/colors';
import {
  userActions,
  finnhubActions,
  modalActions } from '../../_actions/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import moment from 'moment';

export const HomePage = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const symbols = useSelector(state => state.finnhubReducer.symbols);
  const symbolsCount = useSelector(state => state.finnhubReducer.count);
  const votes = useSelector(state => state.finnhubReducer.votes);
  const votesCount = useSelector(state => state.finnhubReducer.votesCount);
  const [ skip, setSkip ] = useState(0);
  const [ votePagination, setVotePagination ] = useState({skip: 0, limit: 9});
  const [ page, setPage ] = useState(1);
  const [ hasMore, setHasMore ] = useState(true);
  const limit = 25;

  useEffect(() => {
    getSymbols();
  }, [skip]);

  useEffect(() => {
    getVotes();
  }, [page]);

  const getSymbols = () => {
    finnhubActions.getFinnhubData({skip, limit}, dispatch);
  };

  const getVotes = () => {
    finnhubActions.getAllVotes({skip: votePagination.skip, limit: votePagination.limit}, dispatch);
  }

  const handlePagination = () => {
    if (symbols?.length >= symbolsCount) {
      setHasMore(false);
      return;
    }
    setSkip(skip + limit);
  };

  const handleVotePagination = (event, newPage) => {
    const voteSkip = (newPage - 1) * votePagination.limit;

    setVotePagination(prev => ({
      skip: voteSkip,
      limit: prev.limit
    }));
    setPage(newPage)
  };

  const clearWallet = () => {
    userActions.resetWallet(user._id, dispatch);
  };

  const redirectToBsc = () => {
    window.open('https://bscscan.com/address/' + user.wallet_address);
  };

  const renderWallet = () => {
    return (
      <>
        <div>Wallet address:</div>
        <div>{user?.wallet_address}</div>
      </>
    )
  };

  const getLastComment = (_votes) => {
    if (_votes.length) {
      const comments = _votes.filter(el => el.comment !== '' || el.comment !== null);
      return comments.[comments.length - 1].comment;
    }
    return 'Empty'
  }

  const redirectToVote = async () => {
    const response = await finnhubActions.getLastVote(user._id, dispatch);
    if (response && response.vote) {
      // const lastCreated = moment(response.vote.created_at).add(24, 'h').format('h:mm');
      // const lastCreated = moment(response.vote.created_at).format('h:mm');
      // console.log('timeNow-_+diff>>>', moment().diff(response.vote.created_at, 'hours'));
      // console.log('lastCreated->>>', lastCreated);
      if (moment().diff(response.vote.created_at, 'hours') <= 24) {
        modalActions.showModal(response, dispatch);
        return
      }
    }
    history.push('/vote')
  }

  return (
    <HomeS.Container>
      <HomeS.UserContainer>
        <div>
          <CardContent>
            <Card>
              <HomeS.UserInfo>
                <div style={{ maxWidth: 170, overflowWrap: 'anywhere'}}>
                  <div>{user?.reddit_name}</div>
                  <div>Total karma: {user?.total_karma}</div>
                  { user?.wallet_address && renderWallet() }
                </div>
                <HomeS.Avatar src={user?.avatar_url} alt='reddit_avatar'/>                        
              </HomeS.UserInfo>
            </Card>
               <div>
                 {
                   user.wallet_address && <Button
                     style={HomeS.WalletButton}
                     variant='contained'
                     disabled={!user.wallet_address}
                     onClick={redirectToBsc}>
                     View Wallet
                   </Button>
                 }
                <Button
                  style={{...HomeS.WalletButton, backgroundColor: '#fff', color: '#fc9226'}}
                  variant='outlined'
                  disabled={!user.wallet_address}
                  onClick={clearWallet}
                >
                  Delete wallet
                </Button>
                <Button
                  style={{...HomeS.WalletButton, backgroundColor: '#fff', color: '#fc9226'}}
                  variant='outlined'
                  onClick={redirectToVote}
                >
                  Create Vote
                </Button>
              </div>
          </CardContent>
        </div>
      </HomeS.UserContainer>
      <HomeS.DataContainer>
        <CardContent>
          <Card>
            <HomeS.Title>Top rankings</HomeS.Title>
            <HomeS.RankingsContainer>
              <HomeS.RankingsHeader>
                <div style={{ marginRight: '11%' }}>#</div>
                <div style={{ marginRight: '14%' }}>Symbol</div>
                <div style={{ marginRight: '17%' }}>Votes</div>
                <div style={{ marginRight: '25%' }}>Description</div>
                <div>Comment</div>
              </HomeS.RankingsHeader>
              <InfiniteScroll
                height={700}
                dataLength={symbols?.length}
                next={handlePagination}
                hasMore={hasMore}
                loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {
                  symbols?.map((symbol, idx) => {
                    return(
                      <CardContent key={idx}>
                        <Card style={{ border: `.5px solid ${colors.darkgrey}` }}>
                          <HomeS.RankingRow>
                            <div>{idx + 1}.</div>
                            <HomeS.SymbolName>{symbol.symbol}</HomeS.SymbolName>
                            <HomeS.VotesCount>{symbol.votes && symbol.votes.length}</HomeS.VotesCount>
                            <HomeS.Description>{symbol.description}</HomeS.Description>
                            <HomeS.RowText>
                              { getLastComment(symbol.votes) }
                            </HomeS.RowText>
                          </HomeS.RankingRow>
                          {/* <HomeS.Comments>See more comments</HomeS.Comments> */}
                        </Card>
                      </CardContent>
                    )
                  })
                }
              </InfiniteScroll>
            </HomeS.RankingsContainer>
          </Card>
        </CardContent>
        <CardContent>
          <Card>
            <HomeS.Title>Recommendations and comments</HomeS.Title>
            <HomeS.DataGridContainer>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>№</TableCell>
                      <TableCell align="right">Symbol</TableCell>
                      <TableCell align="center">User</TableCell>
                      <TableCell align="center">Comment</TableCell>
                      <TableCell align="right">Created at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {votes && votes.length ? votes.map((row, idx) => (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">
                          {
                            idx + ((page - 1) * votePagination.limit) + 1
                          }
                        </TableCell>
                        <TableCell align="right">{row.symbol.displaySymbol}</TableCell>
                        <TableCell align="center">{row.user.reddit_name}</TableCell>
                        <TableCell align="center">{row.comment && row.comment.slice(0, 40)}</TableCell>
                        <TableCell align="right">{moment(row.created_at).format("MMM Do YY")}</TableCell>
                      </TableRow>
                    )) : <div>Votes list is empty</div> }
                  </TableBody>
                </Table>
              </TableContainer>
              {
                votesCount > 9 && <Pagination
                  count={Math.ceil(votesCount / votePagination.limit)}
                  page={page}
                  color="primary"
                  onChange={handleVotePagination}
                  style={{ marginTop: 10 }}
                />
              }
            </HomeS.DataGridContainer>
          </Card>
        </CardContent>
      </HomeS.DataContainer>
    </HomeS.Container>
  )
}