import styled from 'styled-components';
import colors from '../../_config/colors';

const Container = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Buttons = styled.div`  
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const WarnText = styled.div`
  padding: 20px;
  color: ${colors.red};
`;

const AutoComplete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedSymbols = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const Vote = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CommentInput = {
  width: '100%',
  marginTop: 10
}

export const VoteS = {
  Container,
  Buttons,
  WarnText,
  AutoComplete,
  SelectedSymbols,
  CloseIcon,
  Vote,
  CommentInput
}
