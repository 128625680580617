//User
export const SET_REDDIT_AUTHORIZE_URL = 'SET_REDDIT_AUTHORIZE_URL'
export const SET_USER_DATA = 'SET_USER_DATA'
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET'
export const SET_VERIFIED_MEMBERSHIP = 'SET_VERIFIED_MEMBERSHIP'

//Alert
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

//Modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

//Finnhub
export const SET_FINNHUB_DATA = 'SET_FINNHUB_DATA'
export const SET_SEARCHED_SYMBOLS = 'SET_SEARCHED_SYMBOLS'
export const SET_VOTES = 'SET_VOTES'