import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';
import { alertReducer } from './alert.reducer';
import { finnhubReducer } from './finnhub.reducer';
import { modalReducer } from './modal.reducer';


const rootReducer = combineReducers({
  userReducer,
  alertReducer,
  finnhubReducer,
  modalReducer
})

export default rootReducer;