const colors = {
  main: '#234cad',
  secondary: '#1c2f59',
  black: '#000000',
  white: '#ffffff',
  darkgrey: '#81888f',
  grey: '#c5cfd4',
  grey2: '#e4e8eb',
  yellow: '#ffb508',
  lightgrey: '#f2f4f5',
  green: '#00db59',
  pink: '#ff3f8a',
  red: '#ff4e4d', 
};

export default colors;