import { 
  SET_REDDIT_AUTHORIZE_URL, 
  SET_USER_DATA,
  UPDATE_USER_WALLET,
  SET_VERIFIED_MEMBERSHIP } from '../_constants/action.types';

const initialState = {
  authorizeUrl: '',
  user: {}
};

export function userReducer(state = initialState, action) {
  
  const { type, data } = action;

  switch (type) {
    case SET_REDDIT_AUTHORIZE_URL:
      return {
        ...state,
        authorizeUrl: data.url
      }

    case SET_USER_DATA:
      return {
        ...state,
        user: data
      }

    case UPDATE_USER_WALLET:
      return {
        ...state,
        user: {
          ...state.user,
          wallet_address: data.address
        }
      }

    case SET_VERIFIED_MEMBERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          verifiedMembership: action.verifiedMembership
        }
      }

    default:
      return state
  }
}