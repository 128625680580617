import styled from 'styled-components';
import colors from '../../_config/colors';

const Container = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: ${colors.main};
`;

const TitleContainer = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 30px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const NotMember = {
  Container,
  TitleContainer,
  Title
};