import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper, TextField, Button, CircularProgress, Card, CardContent } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { VoteS } from './style';
import { finnhubActions } from "../../_actions";

export const VotePage = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const symbols = useSelector(state => state.finnhubReducer.searchedSymbols) || [];
  const user = useSelector(state => state.userReducer.user);
  const [ votes, setVotes ] = useState([]);

  const [open, setOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  // const loading = open && symbols.length === 0;

  useEffect(() => {
    if (votes.length === 3) setDisableSubmit(false);
  }, [open])

  const navigateBack = () => history.push('/');

  const searchSymbol = async (value) => {
    const filters = {
      limit: 100,
      displaySymbol: value
    };

    await finnhubActions.searchSymbols(filters, dispatch);
  }

  const removeVote = (id) => {
    setVotes(votes && votes.filter(vote => vote._id !== id))
  }

  const handleComment = (comment, voteId) => {
    const vote = votes.find(vote => vote._id === voteId).comment = comment;
    if (vote._id) setVotes(prev => [...prev, vote]);
  }

  const submitVote = async () => {
    const data = {
      user: user._id,
      symbols: votes
    };

    await finnhubActions.createVote(data, dispatch);
  }

  return (
    <VoteS.Container>
      <Paper style={{ width: 600, textAlign: 'center' }}>
        <VoteS.WarnText>
          Pay attention, it is possible to vote once every 24 hours
        </VoteS.WarnText>
        <VoteS.AutoComplete>
          <Autocomplete
            id="buy1"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={symbols}
            onChange={(event, newVote) => {
              if (votes && votes.length !== 3 && !votes.includes(newVote) && newVote) setVotes([...votes, newVote])
            }}
            getOptionLabel={(option) => option.displaySymbol}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={'Start typing...'}
                label="Symbols"
                variant="outlined"
                onChange={(e) => searchSymbol(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/*{loading ? <CircularProgress color="inherit" size={20} /> : null}*/}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </VoteS.AutoComplete>
        <VoteS.SelectedSymbols>
          {
            votes.map((vote, idx) => (
              <CardContent key={idx}>
                <Card>
                  <VoteS.Vote>
                    <div>
                      My #{idx + 1} buy is: {vote.displaySymbol}, {vote.description}
                    </div>
                    <VoteS.CloseIcon onClick={() => removeVote(vote._id)} src="/images/close.svg" alt="close"/>
                  </VoteS.Vote>
                  <TextField
                    multiline
                    label={'Comment'}
                    onChange={(event) => handleComment(event.target.value, vote._id)}
                    style={VoteS.CommentInput}
                  />
                </Card>
              </CardContent>
            ))
          }
          { votes.length !== 3 && <Card>Please choose your #{votes.length + 1}</Card>}
        </VoteS.SelectedSymbols>
        <VoteS.Buttons>
          <Button
            variant='outlined'
            color='secondary'
            onClick={navigateBack}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={disableSubmit}
            style={{ marginLeft: 10 }}
            onClick={submitVote}
          >
            Done
          </Button>
        </VoteS.Buttons>
      </Paper>      
    </VoteS.Container>
  )
};