import config from '../_config/api';
import { handleResponse, authHeader } from '../_helpers';

export const finnhubService = {
  getFinnhubSymbols,
  getFilteredSymbols,
  createVote,
  allVotes,
  getLastVoteByUser
};

async function getFinnhubSymbols(pagination) {
  const { limit, skip } = pagination;

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const request = await fetch(
    `${config.apiUrl}/api/finnhub/symbols?limit=${limit}&skip=${skip}`,
    requestOptions
  )
  return handleResponse(request);
}

async function getFilteredSymbols(pagination) {
  const { limit, skip, displaySymbol } = pagination;
  let url;

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  if (displaySymbol && displaySymbol.length > 0) {
    url = `${config.apiUrl}/api/finnhub/symbols/search?displaySymbol=${displaySymbol}&limit=${limit}&skip=${skip}`
  } else {
    url = `${config.apiUrl}/api/finnhub/symbols/search?limit=${limit}&skip=${skip}`
  }
  const request = await fetch(
    url,
    requestOptions
  )
  return handleResponse(request);
}

async function createVote(data) {
  console.log('data', data)
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  const request = await fetch(
    `${config.apiUrl}/api/vote/create`,
    requestOptions
  )
  return handleResponse(request);
}

async function allVotes(pagination) {
  const { limit, skip } = pagination;

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const request = await fetch(
    `${config.apiUrl}/api/vote/all?limit=${limit}&skip=${skip}`,
    requestOptions
  )
  return handleResponse(request);
}

async function getLastVoteByUser(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const request = await fetch(
    `${config.apiUrl}/api/vote/last/${userId}`,
    requestOptions
  )
  return handleResponse(request);
}