import { userService } from "../_services";

export function handleResponse(response) {
  if (response.status === 401) {
    // auto logout if 401 response returned from api
    userService.logout();
    return Promise.reject({message: 'Email/password in incorrect'})
  }

  return response.text().then((text) => {

    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
