import React, { useState } from 'react';
import { CreateW } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../_actions/index';
import { useHistory } from 'react-router-dom';
import { TabPanel } from '../../_components/TabPanel/index';
import { Greetings } from './greetings';
import { WithoutWallet } from './withoutWallet';
import { Metamask } from './metamask';
import { Bsc } from './bsc';
import { SaveAddress } from './saveAddress';

export const CreateWallet = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const [ tabValue, setTabValue ] = useState(0);
  const [ prevTab, setPrevTab ] = useState();

  const emailData = {
    emailTo: 'vekrum@solutionsny.com',
    subject: 'New user notification',
    message: `A new user has registered in the TopStocks.
     User name ${user.display_name}.
     Karma points ${user.total_karma}. 
     Wallet address: ${user.wallet_address}`
  };

  const handleWalletAddress = (address) => {
    userActions.setUserWallet({address}, dispatch);
  };
  
  const saveWallet = async () => {
    if (validate()) {
      await userActions.sendEmail(emailData, dispatch);
      await userActions.updateUser(user, dispatch, history);
    };
  };

  const validate = () => {
    // I've the worst thoughts about this code duplication.
    if (!user.wallet_address) {
      alertActions.showAlert({
        alertType: 'error',
        alertMessage: 'The wallet address cannot be empty. Please, check the data and try again.'
      }, dispatch);
      return false;
    }
    if (!user.wallet_address.startsWith('0x')) {
      alertActions.showAlert({
        alertType: 'error',
        alertMessage: 'The wallet address must start with the 0x prefix. Please, check the data and try again.'
      }, dispatch);
      return false;
    }
    if (user.wallet_address.length <= 40) {
      alertActions.showAlert({
        alertType: 'error',
        alertMessage: 'The wallet address must be 42 symbols long . Please, check the data and try again.'
      }, dispatch);
      return false;
    }
    return true;
  }

  const _handleTabValue = (from, newValue) => {
    // Workaround, sorry.
    prevTab === 'metamask' && newValue !== 5 ? setTabValue(2) : setTabValue(newValue);
    setPrevTab(from); 
  };  

  return(
    <CreateW.Container>
      <CreateW.Body>       
        <TabPanel 
          value={tabValue}
          index={0}
          style={{overflow: 'auto'}}
        >
          <Greetings handleTabValue={_handleTabValue}/>
        </TabPanel>
        <TabPanel 
          value={tabValue}
          index={1}
          style={{overflow: 'auto'}}
        >
          <WithoutWallet handleTabValue={_handleTabValue}/>
        </TabPanel>
        <TabPanel 
          value={tabValue}
          index={2}
          style={{overflow: 'auto'}}
        >
          <Metamask handleTabValue={_handleTabValue}/>
        </TabPanel>
        <TabPanel 
          value={tabValue}
          index={3}
          style={{overflow: 'auto'}}
        >
          <Bsc handleTabValue={_handleTabValue}/>
        </TabPanel>
        <TabPanel 
          value={tabValue}
          index={4}
          style={{overflow: 'auto'}}
        >
          <Bsc handleTabValue={_handleTabValue}/>
        </TabPanel>
        <TabPanel 
          value={tabValue}
          index={5}
          style={{overflow: 'auto'}}
        >
          <SaveAddress 
            saveWallet={saveWallet}
            handleWalletAddress={handleWalletAddress}
          />
        </TabPanel>
      </CreateW.Body>
    </CreateW.Container>
  )
}