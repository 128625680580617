import {
  SET_FINNHUB_DATA,
  SET_SEARCHED_SYMBOLS,
  SET_VOTES
} from '../_constants/action.types';
import { finnhubService } from '../_services';
import { alertActions } from './alert.actions';


export const finnhubActions = {
  getFinnhubData,
  searchSymbols,
  createVote,
  getAllVotes,
  getLastVote
}

async function getFinnhubData(pagination, dispatch) {
  try {
    const response = await finnhubService.getFinnhubSymbols(pagination);
    success(response);
  } catch (error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_FINNHUB_DATA, data })
  }

  function failure(error) {
    alertActions.showAlert(
      { 
        alertType: 'error',
        alertMessage: error.message
      }, dispatch
    )
  }
}

async function searchSymbols(filters, dispatch) {
  try {
    const response = await finnhubService.getFilteredSymbols(filters);
    success(response);
  } catch (error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_SEARCHED_SYMBOLS, data })
  }

  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: error.message
      }, dispatch
    )
  }
}

async function createVote(data, dispatch) {
  try {
    const response = await finnhubService.createVote(data);
    success(response);
  } catch (error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_SEARCHED_SYMBOLS, data })
    window.location.href = '/'
  }

  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: error.message
      }, dispatch
    )
  }
}

async function getAllVotes(pagination, dispatch) {
  try {
    const response = await finnhubService.allVotes(pagination);
    success(response);
  } catch (error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_VOTES, data })
  }

  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: error.message
      }, dispatch
    )
  }
}

async function getLastVote(userId, dispatch) {
  try {
    return await finnhubService.getLastVoteByUser(userId);
  } catch (error) {
    failure(error);
  }

  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: error.message
      }, dispatch
    )
  }
}