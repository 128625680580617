import styled from 'styled-components';
import colors from '../../_config/colors';

const Container = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: scroll;
  color: ${colors.main};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const Actions = styled.div`
  width: 400px;
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const TextContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  max-height: 600px;
  overflow-y: scroll;
`;

const TextBody = styled.div`
  height: 50vh;    
  display: flex;
  flex-direction: column;
`;

const InfoText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 20px;
`;

const TutorImg = styled.img`
  width: 300px;
  padding: 10px;
  align-self: center;
`;

export const CreateW = {
  Container,
  Body,
  TitleContainer,
  Title,
  Subtitle,
  Actions,
  InfoHeader,
  InfoText,
  TextContainer,
  TextBody,
  Column,
  ButtonsContainer,
  TutorImg
};