import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;  
`

const Content = styled.div`
  height: 50%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`

const SubTitle = styled.div`
  font-size: 22px;
`

const RegularText = styled.div`
  font-size: 20px;
`

const Icon = styled.img`
  width: 500px;
`

export const LoginStyles = { 
  Container,
  Title,
  SubTitle,
  RegularText,
  Icon,
  Content
};