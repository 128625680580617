import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { alertActions } from '../../_actions';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export const CustomAlert = () => {

  const dispatch = useDispatch();
  const alertState = useSelector(state => state.alertReducer);
  const { isOpen, alertType, alertMessage } = alertState;

  const close = () => {
    alertActions.hideAlert(dispatch)
  }

  return (
    <Snackbar
      key={alertMessage}
      open={isOpen}
      onClose={close}
      autoHideDuration={3000}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
    >
      <Alert onClose={close} severity={alertType}>
        {alertMessage}
      </Alert>
    </Snackbar>
  )
}