import config from '../_config/api';
import { handleResponse, authHeader } from '../_helpers';

export const userService = {
  logout,
  getUser,
  updateUser,
  getRedditUrl,
  refreshToken,
  checkMembership,
  sendToEmail,
  clearWalletAddress
};

async function getRedditUrl() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  const request = await fetch(
    `${config.apiUrl}/api/auth/url`,
     requestOptions
  );

  return handleResponse(request);
}

async function logout() {
  const location = window.location;
  if (location.pathname !== '/login') {
    location.href = '/login';
  }
  localStorage.removeItem('user');
}

async function getUser() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const request = await fetch(
    `${config.apiUrl}/api/user/me`,
    requestOptions
  );
  return handleResponse(request);
}

async function updateUser(data) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(data)
  };
  const request = await fetch(
    `${config.apiUrl}/api/user/update`,
    requestOptions
  );
  
  return handleResponse(request);
}

async function checkMembership(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  const request = await fetch(
    `${config.apiUrl}/api/user/checkMembership/${userId}`,
    requestOptions
  )
  return handleResponse(request);
}

async function refreshToken() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const request = await fetch(
    `${config.apiUrl}/api/auth/refreshToken`,
    requestOptions
  )
  return handleResponse(request);
}

async function sendToEmail(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };
  const request = await fetch(
    `${config.apiUrl}/api/user/send_email`,
    requestOptions
  )
  return handleResponse(request);
}

async function clearWalletAddress(userId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };
  const request = await fetch(
    `${config.apiUrl}/api/user/reset_wallet/${userId}`,
    requestOptions
  )
  return handleResponse(request);
}