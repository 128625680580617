import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { LoginPage } from '../_views/LoginPage';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { App } from '../App';
import { CustomAlert } from '../_components/CustomAlert';
import { CustomModal } from '../_components/CustomModal';

export const RouterWrapper = () => {
  return (
    <BrowserRouter>
      <CustomAlert />
      <CustomModal />
      <Switch>
        <PublicRoute exact path="/login" component={LoginPage} />
        <PrivateRoute path="/*" component={App} />
      </Switch>
    </BrowserRouter>
  );
};
