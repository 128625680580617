import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { HeaderStyles } from './style.js';
import { userActions } from '../../_actions/index';

export const BaseLayout = () => {

  const dispatch = useDispatch();

  const logout = () => {
    userActions.logOut(dispatch);
  }
  return (
    <HeaderStyles.Container>
      <HeaderStyles.Content>
        <HeaderStyles.Title>TopStocks</HeaderStyles.Title>
        <Button
          variant='text'
          onClick={logout}
        >
          LogOut
      </Button>
      </HeaderStyles.Content>
    </HeaderStyles.Container>
  );
};
