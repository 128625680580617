import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { CreateW } from './style';

export const Greetings = ({handleTabValue}) => {

  const user = useSelector(state => state.userReducer.user);

  return (
    <CreateW.Column>
      <CreateW.Title>
        {`Congratulations ${user.reddit_name}. You are entitled to 100 free $TOPS tokens`}
      </CreateW.Title>
      <CreateW.Subtitle>
        To send you free tokens we will need you to
        provide the Metamask Wallet address.
        Do you already have Metamask Wallet?
      </CreateW.Subtitle>
      <CreateW.ButtonsContainer>
        <Button 
          variant='contained'
          color='primary'
          onClick={() => handleTabValue('greetings', 2)}
        >
            I have Metamask
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant='contained'
          color='primary'
          onClick={() => handleTabValue('greetings', 1)}
        >
          I don't have any
        </Button>
      </CreateW.ButtonsContainer>
    </CreateW.Column>
  )
}