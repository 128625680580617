import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Paper, TextField } from '@material-ui/core';
import { CreateW } from './style';

export const SaveAddress = ({handleWalletAddress, saveWallet}) => {

  const user = useSelector(state => state.userReducer.user);

  return (
    <CreateW.Column>
      <CreateW.Title>
        {`Congratulations ${user.reddit_name}. You are entitled to 100 free $TOPS tokens`}
      </CreateW.Title>
      <CreateW.Subtitle>
        Please enter your wallet address below to claim your free tokens:
      </CreateW.Subtitle>
      <Paper>
        <CreateW.Actions>
        <TextField 
          label='Enter wallet address'
          onChange={(e) => handleWalletAddress(e.target.value)}
        />
        <Button 
          variant='contained'
          color='primary'
          onClick={saveWallet}
        >
          Save
        </Button>
      </CreateW.Actions>
      </Paper>
    </CreateW.Column>
  )
}