import styled from 'styled-components';
import colors from '../../_config/colors';

const Container = styled.div`
  height: 50px;
  padding: 15px 0px 15px;
  display: flex;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  font-size: 24px;
  color: ${colors.main};
  border-bottom: 0.5px solid;
`;

const Title = styled.span`
  cursor: pointer;
`;

export const HeaderStyles = { Container, Content, Title };
