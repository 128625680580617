import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { CreateW } from './style';

export const Metamask = ({handleTabValue}) => {

  const user = useSelector(state => state.userReducer.user);

  return (
    <CreateW.Column>
      <CreateW.Title>
        {`Congratulations ${user.reddit_name}. You are entitled to 100 free $TOPS tokens`}
      </CreateW.Title>
      <CreateW.Subtitle>
        In order to use Binance Smart Chain (BSC) we need to add it to the Metamask. <br/>
        Do you have The BSC network selected?
      </CreateW.Subtitle>
      <CreateW.ButtonsContainer>
        <Button 
            variant='contained'
            color='primary'
            onClick={() => handleTabValue('metamask', 5)}
          >
            I Have BSC Network
          </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant='contained'
          color='primary'
          onClick={() => handleTabValue('metamask', 3)}
        >
          NO, I don't
        </Button>
      </CreateW.ButtonsContainer>
    </CreateW.Column>
  )
}