import styled from  "styled-components";

const Body = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoTitle = styled.div`
  font-size: 20px;
  padding: 20px;
`;

const Description = styled.div`
  
`;

const CloseButton = {
  marginTop: '10px',
  backgroundColor: '#fc9226',
  color: '#fff',
  width: '50%'
}

export const ModalS = {
  Body,
  InfoContainer,
  InfoTitle,
  CloseButton,
  Description
};