import React, { useEffect } from 'react';
import { LoginStyles } from './style';
import { Button } from '@material-ui/core';
import RedditIcon from '@material-ui/icons/Reddit';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions/index';
import { useLocation } from 'react-router-dom';


export const LoginPage = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const { search } = location;
    const authToken = new URLSearchParams(search).get('authToken');
    if (authToken) userActions.login(authToken);
  }, [])

  const authorizeWithReddit = async () => {
    const response = await userActions.getRedditUrl(dispatch);

    if (response.url) {
      window.open(response.url, '_self');
    }
  }

  return (
    <LoginStyles.Container>
      <LoginStyles.Content>
        <LoginStyles.Title>
          We love these stonks
        </LoginStyles.Title>
        <LoginStyles.SubTitle>
          Claim your free $TOPS tokens by March 31st 2021
        </LoginStyles.SubTitle>
        <LoginStyles.Icon src="./images/fishLogo.svg" alt="logo"/>
        <LoginStyles.RegularText>
          Claim your free $TOPS and start voting on your favorite stocks and cryptos 
        </LoginStyles.RegularText>
        <LoginStyles.RegularText>
          You get your free tokens by providing you were a member of the subreddits before Jan 1, 2021
        </LoginStyles.RegularText>
        <Button
          variant='contained' 
          color='primary'
          style={{
            backgroundColor: '#ff5700',
            marginTop: 30
          }}
          endIcon={<RedditIcon/>}
          onClick={authorizeWithReddit}
        >
          Login with reddit
        </Button>
      </LoginStyles.Content>
    </LoginStyles.Container>
  )
}