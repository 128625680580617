import {
  SHOW_MODAL,
  HIDE_MODAL,
} from '../_constants/action.types';

export const modalActions = {
  showModal,
  hideModal
}

function showModal(data, dispatch) {
  return dispatch({ type: SHOW_MODAL, data })
}

function hideModal(dispatch) {
  return dispatch({ type: HIDE_MODAL })
}