import { userService } from '../_services/index';
import { 
  SET_REDDIT_AUTHORIZE_URL, 
  SET_USER_DATA,
  UPDATE_USER_WALLET,
  SET_VERIFIED_MEMBERSHIP } from '../_constants/action.types';
import { alertActions } from './index';

export const userActions = {
  getRedditUrl,
  login,
  logOut,
  updateUser,
  setUserWallet,
  getUserInfo,
  getMembership,
  refreshRedditToken,
  setVerifiedMembership,
  sendEmail,
  resetWallet
};

async function getRedditUrl(dispatch) {
  // await request();
  try {
    const data = await userService.getRedditUrl();
    success(data)
    return data
  } catch (error) {
    failure(error)
    return error
  }

  // async function request() {
    // loader logic
  // }

  function success(data) {
    return dispatch({ type: SET_REDDIT_AUTHORIZE_URL, data });
  }

  function failure(error) {
    console.log('getRedditUrl---error->>>', error);
  }
}

async function login(token) {
  localStorage.setItem('user', JSON.stringify({token}));
  window.location.href = "/";
}

async function logOut() {
  await userService.logout();
  window.location.href = '/login';
}

async function getUserInfo(dispatch) {
  try {
    const data = await userService.getUser();
    success(data);
    return data;
  } catch (error) {
    failure(error);
  }

  function success(data) {
    dispatch({ type: SET_USER_DATA, data })
  }

  function failure(error) {
    console.log('getUserInfo->>>>>',error);
  }
}

async function updateUser(user, dispatch, history) {
  try {
    const data = await userService.updateUser(user);
    success(data);
  } catch (error) {
    failure(error);
  }

  function success(data) {
    dispatch({ type: SET_USER_DATA, data });
    history.push('/');
    alertActions.showAlert(
      { 
        alertType: 'success', 
        alertMessage: 'You have successfully linked your wallet. We\'ll send you the tokens to the wallet soon.' 
      }, dispatch
    )
  }

  function failure(error) {
    console.log('error updateUser ->>>', error);
  }
};

function setUserWallet(data, dispatch) {
  dispatch({ type:  UPDATE_USER_WALLET, data});
}

async function getMembership(userId, dispatch) {
  try {
    const data = await userService.checkMembership(userId);
    success(data);
    return data;
  } catch (error) {
    failure(error);
  }

  function success(data) {
    console.log('Successfully->>>');
  }

  function failure() {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: 'Oops, something wrong when we try to get a user membership from reddit api.'
      }, dispatch
    )
  }
}

async function refreshRedditToken(dispatch) {
  console.log('refreshRedditTocken->>>>>>>')
  try {
    const data = await userService.refreshToken();
    success(data);
  } catch (error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_USER_DATA, data });
  }
  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: 'Oops, something wrong when we try to get a refresh token from reddit.'
      }, dispatch
    )
  }   
}

function setVerifiedMembership(dispatch) {
  dispatch({ type: SET_VERIFIED_MEMBERSHIP,  verifiedMembership: true })
}

async function sendEmail(data, dispatch) {
  try {
    const response = await userService.sendToEmail(data);
    success(response);
  } catch (error) {
    failure(error);
  }

  function success(data) {
    console.log('Email sent successfully')
  }

  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: 'Oops, something wrong when we try to send email.'
      }, dispatch
    )
  }
}

async function resetWallet(userId, dispatch) {
  try {
    const response = await userService.clearWalletAddress(userId);
    success(response);
  } catch(error) {
    failure(error)
  }

  function success(data) {
    dispatch({ type: SET_USER_DATA, data });
    alertActions.showAlert(
      {
        alertType: 'success',
        alertMessage: 'Successfully deleted'
      }, dispatch
    )
  }
  function failure(error) {
    alertActions.showAlert(
      {
        alertType: 'error',
        alertMessage: 'Oops, something wrong when we try to delete wallet'
      }, dispatch
    )
  }
}