import {
  SET_FINNHUB_DATA,
  SET_SEARCHED_SYMBOLS,
  SET_VOTES
} from '../_constants/action.types';

const initialState = {
  symbols: [],
  searchedSymbols: [],
  count: 0,
  votes: [],
  votesCount: 0
}

export function finnhubReducer(state = initialState, action) {

  const { type, data } = action;

  switch(type) {

    case SET_FINNHUB_DATA:
      return {
        ...state,
        symbols: [
          ...state.symbols,
          ...data.symbols
        ],
        count: data.count
       }

    case SET_SEARCHED_SYMBOLS:
      return {
        ...state,
        searchedSymbols: data.symbols
      }

    case SET_VOTES:
      return {
        ...state,
        votes: data.votes,
        votesCount: data.votesCount
      }

    default:
      return state
  }
}