import { HIDE_ALERT, SHOW_ALERT } from '../_constants/action.types';

const initialState = {
  alertType: '',
  alertMessage: '',
  isOpen: false
}

export function alertReducer(state = initialState, action) {

  switch(action.type) {

    case SHOW_ALERT:
      return {
        isOpen: true,
        alertType: action.data.alertType,
        alertMessage: action.data.alertMessage
      }

    case HIDE_ALERT:
      return initialState

    default:
      return state
  }
}