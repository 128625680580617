import { SHOW_MODAL, HIDE_MODAL } from '../_constants/action.types';

const initialState = {
  isOpen: false,
  data: {
    comment: '',
    created_at: ''
  }
}

export function modalReducer(state = initialState, action) {

  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
        data: action.data.vote
      }
    case HIDE_MODAL:
      return {
        ...state,
        isOpen: false
      }
    default:
      return state
  }

}