import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { CreateW } from './style';

export const WithoutWallet = ({handleTabValue}) => {

  const user = useSelector(state => state.userReducer.user);

  return (
    <CreateW.Column>
      <CreateW.Title>
        {`Congratulations ${user.reddit_name}. You are entitled to 100 free $TOPS tokens`}
      </CreateW.Title>
      <CreateW.Subtitle>
        To send you free tokens we will need you to
        create the Metamask Wallet.
      </CreateW.Subtitle>
      <CreateW.TextContainer>
        <CreateW.InfoText>
          1. Install Metamask from the project’s official  
          <a href='https://metamask.io/download.html' target='_blank'> website</a> or an app store.
        </CreateW.InfoText>
        <CreateW.InfoText>
          2. Chrome, Firefox, Brave, and Edge all support. 
          Opera users can use <br/> &nbsp; Metamask through Chrome  
          <a href='https://addons.opera.com/en/extensions/details/install-chrome-extensions/' target='_blank'> extensions</a>              
        </CreateW.InfoText>
        <CreateW.InfoText>
          3. Once you have installed Metamask, click on the Metamask icon in <br/> &nbsp; your browser’s toolbar to open the app.
        </CreateW.InfoText>
        <CreateW.InfoText>
          4. Click on “Get Started.”
        </CreateW.InfoText>
        <CreateW.InfoText>
          5. Click on “Create a Wallet” to make a new Ethereum wallet.
        </CreateW.InfoText>
        <CreateW.InfoText>
          6. Create a password for your wallet.
        </CreateW.InfoText>
        <CreateW.InfoText>
          7. Click on the grey area to unlock your seed phrase. Be sure <br/> &nbsp; to store this seed phrase safely.
        </CreateW.InfoText>
        <CreateW.InfoText>
          8. Enter the address of your newly created wallet above.
        </CreateW.InfoText>
      </CreateW.TextContainer>
      <CreateW.ButtonsContainer>
        <Button 
            variant='contained'
            color='primary'
            onClick={() => handleTabValue('without', 0)}
          >
            Back
          </Button>
        <Button
          style={{ backgroundColor: 'green', marginLeft: 20 }}
          variant='contained'
          color='primary'
          onClick={() => handleTabValue('without', 3)}
        >
          Proceed
        </Button>
      </CreateW.ButtonsContainer>
    </CreateW.Column>
  )
}