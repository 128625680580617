export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return {
      "Content-type": "application/json; charset=UTF-8",
      "Auth-Token": user.token,
    };
  } else {
    return {};
  }
}
