import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { userActions } from './_actions/index';
import moment from 'moment';

// Views
import { BaseLayout } from './_views/BaseLayout/index';
import { HomePage } from './_views/HomePage';
import { CreateWallet } from './_views/CreateWalletPage';
import { NotAMember } from './_views/NotAMemberPage';
import { VotePage } from './_views/VotePage';

export const App = () => {

  const useStyles = makeStyles(() => ({
    content: {
      flexGrow: 1
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    decideWhereRedirect();
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);

  const decideWhereRedirect = async () => {
    const userResp = await userActions.getUserInfo(dispatch);

    if (isNeedToRefreshToken(userResp)) {
      await userActions.refreshRedditToken(dispatch);
    }

    const response = await userActions.getMembership(userResp._id, dispatch);
    const isExist = response?.some(subReddit => subReddit.data.name === 't5_3wm2ay');
    const timeNow = moment().format('h:mm');
    const lastLoggedTime = moment(userResp.last_logged_in).format('h:mm');

    // This will work if the user doesn't reload the page, 
    // and the condition will be taken into account over time.
    if (!isExist && timeNow === lastLoggedTime && !user.verifiedMembership) history.push('/info');
    if (isExist && !userResp?.wallet_address) history.push('/create_wallet');
  }

  const isNeedToRefreshToken = (userResp) => {
    // const whenLoggedOffset = moment(user.last_logged_in).add(1, 'h').format('h:mm');
    // console.log('user->>>', userResp)
    // console.log('user.last_logged_in->>>', userResp.last_logged_in);
    // console.log('offsetLoggedIn->>>>', moment().diff(userResp.last_logged_in, 'hours'));
    // const timeNow = moment().format('h:mm');
    return moment().diff(userResp.last_logged_in, 'hours') >= 1
    // return whenLoggedOffset <= timeNow
  }

  return (
    <main className={classes.content}>
      <BaseLayout/>
      <Switch>
        <Route exact path='/' component={HomePage}/>
        <Route exact path='/create_wallet' component={CreateWallet}/>
        <Route exact path='/info' component={NotAMember}/>
        <Route exact path='/vote' component={VotePage}/>
      </Switch>
    </main>
  );
}