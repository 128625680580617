import styled from 'styled-components';
import colors from '../../_config/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const UserContainer = styled.div`
  display: flex;
  max-width: 350px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfo = styled.div`
  padding: 20px;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const WalletInfo = styled.div`
  padding: 20px;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-left: 10px;
`;

const Title = styled.div`
  padding: 5px;
  font-size: 22px;
  align-self: center;
`;

const RankingsContainer = styled.div`
  min-width: 800px;
  overflow-y: scroll;  
`;

const DataGridContainer = styled.div`
  height: 58%;
  overflow-y: scroll;
  min-width: 800px;
`;

const RankingsHeader = styled.div`
  // min-width: 800px;
  display: flex;
  padding: 0px 40px 0px;
  // justify-content: space-between;
`;

const RankingsHeaderItem = styled.div`
  // min-width: 150px;
  margin-left: 10%;
`;

const RankingRow = styled.div`
  min-width: 65vw;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const SymbolName = styled.div`
  min-width: 100px;
`;

const VotesCount = styled.div`
  min-width: 100px;
`;

const Description = styled.div`
  min-width: 300px;
`;

const RowText = styled.div`
  width: 200px;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${colors.lightgrey};
`;

const Comments = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 12px;
`;

const WalletButton = {
  marginTop: '10px', 
  backgroundColor: '#fc9226', 
  color: '#fff',
  width: '100%'
};

export const HomeS = {
  Container,
  UserContainer,
  DataContainer,
  DataGridContainer,
  UserInfo,
  WalletInfo,
  Avatar,
  Title,
  RankingRow,
  SymbolName,
  VotesCount,
  Description,
  RowText,
  Comments,
  RankingsContainer,
  RankingsHeader,
  RankingsHeaderItem,
  WalletButton
};